<template>
  <v-expand-transition>
    <v-toolbar dark :color="color"  class="text-right">
      <v-col cols="6" sm="6" md="3"  :offset="$vuetify.breakpoint.smAndDown?0:7" class="px-1" align="end">
        <v-btn
            class="font-weight-bold"
            depressed
            outlined
            :loading="loading"
            @click="resetFilters"
        >                    <v-icon left>mdi-filter-remove-outline</v-icon>
          Reset filters
        </v-btn>
      </v-col>
      <v-col cols="6" sm="6" md="2"  class="px-1">
        <v-btn
            depressed
            class="font-weight-bold"
            :loading="loading"
            outlined
            @click="applyFilters"
        >Apply filters
        </v-btn>
      </v-col>
    </v-toolbar>
  </v-expand-transition>
</template>
<script>
export default {
  name:'FilterToolbar',
  methods: {
      resetFilters() {
        this.$emit('resetFilters')
      },
      applyFilters() {
        this.$emit('applyFilters')
      },
  },
  props: {
    loading: {
      required: true,
      type: Boolean
    },
    color: {
      required: false,
      type: String,
      default:'light-blue'
    },
  },
}
</script>
